import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { uiActions } from "../../store/ui-slice";
import { useDispatch } from "react-redux";
import AuthContext from "../../contexts/JWTAuthContext";
import { useNavigate } from "react-router-dom";

const MenuUserDropdown = (props) => {
  const { hideMenu } = props;
  const [isHover, setIsHover] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [userRoleId, setUserRoleId] = useState(-1);
  const [displayUser, setDisplayUser] = useState("");
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    const curStrUser = localStorage.getItem("user");
    const curUser = JSON.parse(curStrUser);
    const curRoleId = (curUser && curUser.roleid) || -1;
    setUserRoleId(curRoleId);
    setDisplayUser((curUser && curUser.displayName) || "");
    // console.log(curUser)
  }, [userRoleId, isAuthenticated]);

  const clickDangNhapHandler = (evt) => {
    evt.preventDefault();
    hideMenu();
    dispatch(uiActions.setShowRegister(false));
    dispatch(uiActions.setShowLogin(true));
  };
  const clickDangKyHandler = (evt) => {
    evt.preventDefault();
    hideMenu();
    dispatch(uiActions.setShowLogin(false));
    dispatch(uiActions.setShowRegister(true));
  };

  const clickLogoutHandler = (evt) => {
    evt.preventDefault();
    hideMenu();
    logout();
  };
  const clickInforUser = (evt) => {
    evt.preventDefault();
    hideMenu();
    history("/thong-tin-nguoi-dung");
  };

  useEffect(() => {
    let mTimeOut = null;
    if (isHover) {
      setIsShow(true);
    } else {
      mTimeOut = setTimeout(() => {
        console.log("check hover");
        setIsShow(false);
      }, 500);
    }
    return () => {
      // console.log('IsHover')
      if (mTimeOut) {
        clearTimeout(mTimeOut);
      }
    };
  }, [isHover]);

  return (
    <li>
      <div
        className={`dropdown dropdown-user ${isShow ? "show" : ""}`}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <a
          className="dropdown-toggle"
          role="button"
          id="dropdownuser"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={isShow ? "true" : "false"}
        >
          <img className="" src="images/icon/account_white.png" alt="" />
          <span className={`${isAuthenticated ? "" : "d-none"}`}>
            {displayUser}
          </span>
        </a>

        <div
          className={`dropdown-menu ${isShow ? "show" : ""}`}
          aria-labelledby="dropdownuser"
          style={{ left: "-100px" }}
        >
          {!isAuthenticated && (
            <button
              className="dropdown-item"
              data-toggle="modal"
              data-target="#login-modal"
              onClick={clickDangNhapHandler}
            >
              Đăng nhập
            </button>
          )}

          {!isAuthenticated && (
            <button
              className="dropdown-item"
              data-toggle="modal"
              data-target="#register-modal"
              onClick={clickDangKyHandler}
            >
              Đăng ký
            </button>
          )}

          {/* <a className="dropdown-item d-none" href="#">
                        Đăng xuất
                    </a> */}
          {/* {isAuthenticated && (
                        <button
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#register-modal"
                        >
                            {displayUser}
                        </button>
                    )} */}
          {isAuthenticated && (
            <button
              className="dropdown-item"
              data-toggle="modal"
              data-target="#register-modal"
              onClick={clickLogoutHandler}
            >
              Đăng xuất
            </button>
          )}
          {isAuthenticated && userRoleId === 5 && (
            <Link className="dropdown-item" to={"/lich-su-mua-hang"}>
              Lịch sử mua hàng
            </Link>
          )}
          {isAuthenticated && (
            <button
              className="dropdown-item"
              data-toggle="modal"
              data-target="#register-modal"
              onClick={clickInforUser}
            >
              Thông tin người dùng
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default MenuUserDropdown;
