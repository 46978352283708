import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppContext from "./contexts/AppContext";
import store from "./store/index";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import viMessages from "devextreme/localization/messages/vi.json";
import { locale, loadMessages } from "devextreme/localization";

// import "./App.css";

// import KdcContentLazy from "./KdcContentLazy";
import MainContentLazy from "./MainContentLazy";

function App() {
  loadMessages(viMessages);
  locale("vi");
  return (
    <AppContext.Provider value={{}}>
      <Provider store={store}>
        <BrowserRouter>
          <MainContentLazy />
        </BrowserRouter>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
