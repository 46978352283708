import { createSlice } from '@reduxjs/toolkit'

const thongkeSlice = createSlice({
    name: 'thongke',
    initialState: {
        isLoaded: true,
        dataDoanhThu: null,
        dataDoanhThuDiem: null,
        dataLuotThamQuan: null,
        dataVeHuy: null,
        dataDiaDiem: [],
        dataLoaiVe: [],
        dataDoiTuong: [],
        dataQuyen: [],
        dataDoanhThuNhanVien: [],
        dataVeHuyNhanVien: [],
        error: null,
        //NEW
        dataTKDoanhThu: null,
        dataTKLuotThamQuan: null,
        dataTKLuotThamQuanNam: null,
        dataTKSoVe: null,
        dataTKSoVeHuy: null,
        dataTKDoanhThuNuocNgoai: null,
        dataTKDoanhThuThanhToan: null,
        dataTKDoanhThuVe: null,
        dataGridTKDoanhThu: [],
        dataGridTKLuotThamQuan: [],
        dataGridTKLuotThamQuanNam: [],
        dataGridTKSoVeHuy: [],
        dataGridTKDoanhThuNuocNgoai: [],
        dataGridTKDoanhThuThanhToan: [],
        dataGridTKDoanhThuVe: [],
        //Last
        dataTKDoanhThuLoaiVe: null,
        dataGridTKDoanhThuLoaiVe: [],
        dataTKLuotKhach: null,
        dataGridTKLuotKhach: [],
        //TongHop
        dataGridTKTongHopVe: [],
        dataGridTKTongHopDoanhThu: [],
        dataTKHeader: [],
        dataTKTipod: []
    },
    reducers: {
        thongKeRequest(state, action) {
            state.isLoaded = false
        },
        thongKeDiaDiemData(state, action) {
            state.isLoaded = true
            state.dataDiaDiem = action.payload
        },
        thongKeLoaiVeData(state, action) {
            state.isLoaded = true
            state.dataLoaiVe = action.payload
        },
        thongKeDoiTuongData(state, action) {
            state.isLoaded = true
            state.dataDoiTuong = action.payload
        },
        thongKeDoiTuongLoaiveData(state, action) {
            state.isLoaded = true
            state.dataDoiTuong = action.payload[0]
            state.dataLoaiVe = action.payload[1]
        },
        thongKeDataDoanhThuSuccess(state, action) {
            state.isLoaded = true
            state.dataDoanhThu = action.payload.doanhThu
            state.dataDoanhThuNhanVien = action.payload.nhanVien
        },
        thongKeDataDoanhThuDiaDiemSuccess(state, action) {
            state.isLoaded = true
            state.dataDoanhThuDiem = action.payload
        },
        thongKeDataLuotThamQuanSuccess(state, action) {
            state.isLoaded = true
            state.dataLuotThamQuan = action.payload
        },
        thongKeDataVeHuySuccess(state, action) {
            state.isLoaded = true
            state.dataVeHuy = action.payload.veHuy
            state.dataVeHuyNhanVien = action.payload.nhanVien
        },
        thongKeAllSuccess(state, action) {
            state.isLoaded = true
            state.dataDoanhThu = action.payload[0].doanhThu
            state.dataDoanhThuNhanVien = action.payload[0].nhanVien
            state.dataLuotThamQuan = action.payload[1]
            state.dataDoanhThuDiem = action.payload[2]
            state.dataVeHuy = action.payload[3].veHuy
            state.dataVeHuyNhanVien = action.payload[3].nhanVien
        },
        thongKeHomeSuccess(state, action) {
            state.isLoaded = true
            state.dataDoanhThu = action.payload[0].doanhThu
            state.dataDoanhThuNhanVien = action.payload[0].nhanVien
            state.dataLuotThamQuan = action.payload[1]
            state.dataDoanhThuDiem = action.payload[2]
            state.dataVeHuy = action.payload[3].veHuy
            state.dataVeHuyNhanVien = action.payload[3].nhanVien
            state.dataDiaDiem = action.payload[4]
            state.dataLoaiVe = action.payload[5]
            state.dataDoiTuong = action.payload[6]
        },
        thongKeDataFailed(state, action) {
            state.isLoaded = true
            state.error = action.payload
        },
        getTemplateByRoleSuccess(state, action) {
            state.isLoaded = true
            state.dataQuyen = action.payload
        },
        getTemplateByRoleFailed(state, action) {
            state.isLoaded = true
            state.error = action.payload
        },
        //NEW
        tKDataDoanhThuSuccess(state, action) {
            state.isLoaded = true
            state.dataTKDoanhThu = action.payload.chart
            state.dataGridTKDoanhThu = action.payload.grid
        },
        tKDataLuotThamQuanSuccess(state, action) {
            state.isLoaded = true
            state.dataTKLuotThamQuan = action.payload.chart
            state.dataGridTKLuotThamQuan = action.payload.grid
        },
        tKDataLuotThamQuanNamSuccess(state, action) {
            state.isLoaded = true
            state.dataTKLuotThamQuanNam = action.payload.chart
            state.dataGridTKLuotThamQuanNam = action.payload.grid
        },
        tKDataSoVeSuccess(state, action) {
            state.isLoaded = true
            state.dataTKSoVe = action.payload.chart
            state.dataGridTKDoanhThu = action.payload.grid
        },
        tKDataSoVeHuySuccess(state, action) {
            state.isLoaded = true
            state.dataTKSoVeHuy = action.payload.chart
            state.dataGridTKSoVeHuy = action.payload.grid
        },
        tKDataDoanhThuNuocNgoaiSuccess(state, action) {
            state.isLoaded = true
            state.dataTKDoanhThuNuocNgoai = action.payload.chart
            state.dataGridTKDoanhThuNuocNgoai = action.payload.grid
        },
        tKDataDoanhThuThanhToanSuccess(state, action) {
            state.isLoaded = true
            state.dataTKDoanhThuThanhToan = action.payload.chart
            state.dataGridTKDoanhThuThanhToan = action.payload.grid
        },
        tKDataDoanhThuVeSuccess(state, action) {
            state.isLoaded = true
            state.dataTKDoanhThuVe = action.payload.chart
            state.dataGridTKDoanhThuVe = action.payload.grid
        },
        tKDataAllSuccess(state, action) {
            state.isLoaded = true
            state.dataTKDoanhThu = action.payload[0].chart
            state.dataGridTKDoanhThu = action.payload[0].grid
            state.dataTKLuotThamQuan = action.payload[1].chart
            state.dataGridTKLuotThamQuan = action.payload[1].grid
            state.dataTKLuotThamQuanNam = action.payload[2].chart
            state.dataGridTKLuotThamQuanNam = action.payload[2].grid
            state.dataTKSoVe = action.payload[0].chart
            state.dataTKSoVeHuy = action.payload[3].chart
            state.dataGridTKSoVeHuy = action.payload[3].grid
            state.dataTKDoanhThuNuocNgoai = action.payload[4].chart
            state.dataGridTKDoanhThuNuocNgoai = action.payload[4].grid
            state.dataTKDoanhThuThanhToan = action.payload[5].chart
            state.dataGridTKDoanhThuThanhToan = action.payload[5].grid
            state.dataTKDoanhThuVe = action.payload[6].chart
            state.dataGridTKDoanhThuVe = action.payload[6].grid
            //Last
            state.dataTKDoanhThuLoaiVe = action.payload[7].chart
            state.dataGridTKDoanhThuLoaiVe = action.payload[7].grid
            state.dataTKLuotKhach = action.payload[8].chart
            state.dataGridTKLuotKhach = action.payload[8].grid
        },
        //Last
        tKDataDoanhThuLoaiVeSuccess(state, action) {
            state.isLoaded = true
            state.dataTKDoanhThuLoaiVe = action.payload.chart
            state.dataGridTKDoanhThuLoaiVe = action.payload.grid
        },
        tKDataLuotKhachSuccess(state, action) {
            state.isLoaded = true
            state.dataTKLuotKhach = action.payload.chart
            state.dataGridTKLuotKhach = action.payload.grid
        },
        //ThongKeTongHop
        tKDataTongHopVeSuccess(state, action) {
            state.isLoaded = true
            state.dataGridTKTongHopVe = action.payload
        },
        tKDataTongHopDoanhThuSuccess(state, action) {
            state.isLoaded = true
            state.dataGridTKTongHopDoanhThu = action.payload
        },
        tKDataTongHopSuccess(state, action) {
            state.isLoaded = true
            state.dataGridTKTongHopVe = action.payload.ve
            state.dataGridTKTongHopDoanhThu = action.payload.doanhThu
        },
        tKDataHeaderSuccess(state, action) {
            state.isLoaded = true
            state.dataTKHeader = action.payload
        },
        tKDataTipodSuccess(state, action) {
            state.isLoaded = true
            state.dataTKTipod = action.payload
        },
    },
})

export const thongkeActions = thongkeSlice.actions

export default thongkeSlice
