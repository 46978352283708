import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenActions } from "../store/authen-slice";
import MenuDropdown from "./trangchu/MenuDropdown";
import MenuUserDropdown from "./trangchu/MenuUserDropdown";
import AuthContext from "../contexts/JWTAuthContext";
import { subscribe, unsubscribe } from "../customEvents";
import { uiActions } from "../store/ui-slice";
import { placeCartActions } from "../store/placeCart-slice";

// const arrShowMuaVe = [6, 4, 7]
// const arrShowQuanLyVe = [4, 5, 7]

// const dropdownDatve = [
//     {
//         routePath: 'dat-ve-tham-quan',
//         routeTitle: 'Đặt vé tham quan',
//     },
//     {
//         routePath: 'dat-ve-tham-quan',
//         routeTitle: 'Hướng dẫn đặt vé',
//     },
//     {
//         routePath: 'dat-ve-tham-quan',
//         routeTitle: 'Kiểm tra mã đặt vé',
//     },
//     {
//         routePath: 'dat-ve-tham-quan',
//         routeTitle: 'Chính sách hoàn vé',
//     },
// ]

const dropdownThongTin = [
  // {
  //     routePath: 'dat-ve-tham-quan',
  //     routeTitle: 'Giá vé',
  // },
  {
    routePath: "chuong-trinh-khuyen-mai",
    routeTitle: "Khuyến mãi",
  },
  {
    routePath: "noi-quy",
    routeTitle: "Nội quy tham gia",
  },
  {
    routePath: "thoi-gian-tham-quan",
    routeTitle: "Thời gian tham quan",
  },
  {
    routePath: "tour-moi",
    routeTitle: "Chương trình tour mới",
  },
  {
    routePath: "lich-su-mua-ve",
    routeTitle: "Tra cứu vé",
  },
];

const MainHeader = (props) => {
  const API_URL = process.env.REACT_APP_URL;
  const dispatch = useDispatch();
  const { isAuthenticated, user, isInCms, isInitialised } =
    useContext(AuthContext);
  const [userRoleId, setUserRoleId] = useState(-1);
  const [showMenu, setShowMenu] = useState(false);
  const [menus, setMenus] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    const curStrUser = localStorage.getItem("user");
    const curUser = JSON.parse(curStrUser);
    const curRoleId = (curUser && curUser.roleid) || -1;
    setUserRoleId(curRoleId);
  }, [userRoleId, isAuthenticated]);

  const logoutHandler = (evt) => {
    dispatch(authenActions.logout());
  };

  const showHandler = (evt) => {
    setShowMenu(!showMenu);
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    // console.log(isAuthenticated)
    // console.log(user)
    // if (isAuthenticated && user && user.roleid && user.roleid === 4) {
    //     history('/ticket-scan')
    // }
    console.log(
      `${API_URL}/api/menu/getheader/${(user && user.username) || ""}`
    );

    fetch(`${API_URL}/api/menu/getheader/${(user && user.username) || ""}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data)
        setMenus(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      isAuthenticated &&
      isInitialised &&
      user &&
      user.roleid &&
      user.roleid === 3
    ) {
      dispatch(uiActions.setShowSelectDefaultPlace(true));
    } else {
      dispatch(placeCartActions.setDefaultTicketType(1));
      dispatch(uiActions.setShowSelectDefaultPlace(false));
    }
  }, [isAuthenticated, isInitialised]);

  useEffect(() => {
    subscribe("changeLoginState", (data) => {
      if (data.detail.isLogin && data.detail.roleId === 4) {
        history("/ticket-scan");
      } else {
        history("/vedientu");
      }
    });
    subscribe("changeCurrentPage", (data) => {
      setShowMenu(false);
    });
    return () => {
      unsubscribe("changeLoginState");
      unsubscribe("changeCurrentPage");
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light ${
        isInCms ? "d-none" : ""
      }`}
      id="ftco-navbar"
    >
      <div className="container">
        <Link className="navbar-brand" to={"/vedientu"}>
          <img src="images/logo.svg" alt="img-fluid" />
        </Link>
        <button
          className={`${
            showMenu ? "navbar-toggler" : "navbar-toggler collapsed"
          }`}
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={showHandler}
        >
          <span className="oi oi-menu"></span>
        </button>

        <div
          className={`${
            showMenu
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          }`}
          id="ftco-nav"
        >
          <ul className="navbar-nav">
            {menus.map((el, idx) => {
              if (el.isLeaf === "0") {
                return (
                  <MenuDropdown
                    key={idx}
                    arrDropdownItem={dropdownThongTin}
                    dropdownId={`menu_header_${el.id}`}
                    title={el.name}
                    routePath={`home-page`}
                  />
                );
              }
              if (el.name === "CMS") {
                return (
                  <li className="nav-item" key={idx}>
                    <a class="nav-link" href="/cms">
                      CMS
                    </a>
                  </li>
                );
              }
              return (
                <li className="nav-item" key={idx}>
                  <NavLink
                    to={el.path}
                    className={(navData) => {
                      return navData.isActive ? "nav-link active" : "nav-link";
                    }}
                  >
                    {el.name}
                  </NavLink>
                </li>
              );
            })}
            {/* <li className="nav-item">
                            <NavLink
                                to="/vedientu"
                                className={(navData) => {
                                    return navData.isActive
                                        ? 'nav-link active'
                                        : 'nav-link'
                                }}
                            >
                                Trang chủ
                            </NavLink>
                        </li>

                        {(!isAuthenticated ||
                            userRoleId === -1 ||
                            userRoleId === 5) && (
                            <li className="nav-item">
                                <NavLink
                                    to="/chon-ve"
                                    className={(navData) => {
                                        return navData.isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }}
                                >
                                    Mua vé
                                </NavLink>
                            </li>
                        )}
                        {isAuthenticated &&
                            (userRoleId === 1 || userRoleId === 3) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/ban-ve"
                                        className={(navData) => {
                                            return navData.isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }}
                                    >
                                        Bán vé
                                    </NavLink>
                                </li>
                            )}
                        {(!isAuthenticated ||
                            userRoleId === -1 ||
                            userRoleId === 5) && (
                            <li className="nav-item">
                                <NavLink
                                    to="/dat-ve"
                                    className={(navData) => {
                                        return navData.isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }}
                                >
                                    đặt vé
                                </NavLink>
                            </li>
                        )}
                        {(!isAuthenticated ||
                            userRoleId === -1 ||
                            userRoleId === 5) && (
                            <MenuDropdown
                                arrDropdownItem={dropdownThongTin}
                                dropdownId="thongtin"
                                title={'Thông tin'}
                                routePath={`home-page`}
                            />
                        )}
                        {(!isAuthenticated ||
                            userRoleId === -1 ||
                            userRoleId === 5) && (
                            <li className="nav-item">
                                <NavLink
                                    to="/su-kien"
                                    className={(navData) => {
                                        return navData.isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }}
                                >
                                    Sự kiện
                                </NavLink>
                            </li>
                        )}
                        {(!isAuthenticated ||
                            userRoleId === -1 ||
                            userRoleId === 5) && (
                            <li className="nav-item">
                                <NavLink
                                    to="/diem-den"
                                    className={(navData) => {
                                        return navData.isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }}
                                >
                                    Khám phá
                                </NavLink>
                            </li>
                        )}
                        {(!isAuthenticated || userRoleId !== 4) && (
                            <li className="nav-item">
                                <NavLink
                                    to="/thong-ke"
                                    className={(navData) => {
                                        return navData.isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }}
                                >
                                    Thống kê
                                </NavLink>
                            </li>
                        )}
                        {isAuthenticated &&
                            userRoleId !== -1 &&
                            userRoleId !== 4 &&
                            userRoleId !== 5 &&
                            userRoleId !== 7 && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/danh-sach-ve"
                                        className={(navData) => {
                                            return navData.isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }}
                                    >
                                        quản lý vé
                                    </NavLink>
                                </li>
                            )}
                        {isAuthenticated && userRoleId === 4 && (
                            <li className="nav-item">
                                <NavLink
                                    to="/ticket-scan"
                                    className={(navData) => {
                                        return navData.isActive
                                            ? 'nav-link active'
                                            : 'nav-link'
                                    }}
                                >
                                    Quét vé
                                </NavLink>
                            </li>
                        )}
                        {isAuthenticated &&
                            (userRoleId === 2 || userRoleId === 1) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/admin-tool"
                                        className={(navData) => {
                                            return navData.isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }}
                                    >
                                        CMS
                                    </NavLink>
                                </li>
                            )}
                        {isAuthenticated &&
                            (userRoleId === 6 || userRoleId === 1) && (
                                <li className="nav-item">
                                    <NavLink
                                        to="/admin-tool/quanlyykien"
                                        className={(navData) => {
                                            return navData.isActive
                                                ? 'nav-link active'
                                                : 'nav-link'
                                        }}
                                    >
                                        Ý kiến
                                    </NavLink>
                                </li>
                            )} */}
          </ul>
          <div className="action-nav">
            <ul>
              <li>
                <a className="cart" href="#">
                  <img src="images/icon/cart_white.png" alt="" />
                </a>
                {(!isAuthenticated ||
                  userRoleId === -1 ||
                  userRoleId === 5) && (
                  <Link className="cart show" to={"/gio-hang"}>
                    <img src="images/icon/cart_active.png" alt="" />
                  </Link>
                )}
              </li>
              <li>
                <div className="dropdown dropdown-language">
                  <a
                    className="dropdown-toggle language-show"
                    href="#"
                    role="button"
                    id="dropdownlanguage"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      className="vi-vn active"
                      src="images/icon/vi-VN.png"
                      alt=""
                    />
                    <img className="en-us" src="images/icon/en-US.png" alt="" />
                  </a>

                  <div
                    className="dropdown-menu language-dropdown"
                    aria-labelledby="dropdownlanguage"
                  >
                    {/* <div className="form-check">
                                            <input
                                                className="form-check-input input-vi"
                                                type="radio"
                                                name="language"
                                                id="language1"
                                                value="option1"
                                                checked
                                            />
                                            <label
                                                className="form-check-label"
                                                for="language1"
                                            >
                                                Vietnamese
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input input-en"
                                                type="radio"
                                                name="language"
                                                id="language2"
                                                value="option2"
                                            />
                                            <label
                                                className="form-check-label"
                                                for="language2"
                                            >
                                                English
                                            </label>
                                        </div> */}
                  </div>
                </div>
              </li>
              <MenuUserDropdown hideMenu={hideMenu} />
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default MainHeader;
