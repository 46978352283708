import { createSlice } from '@reduxjs/toolkit'

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        arrCustomerType: [],
        exportReceipt: false,
        arrReceipt: [],
        currentPromotion: {},
        currentReceiptDefault: {},
    },
    reducers: {
        setArrCustomerType(state, action) {
            state.arrCustomerType = [...action.payload.data]
        },

        setExportReceipt(state, action) {
            state.exportReceipt = action.payload
        },

        setArrReceipt(state, action) {
            const arrResult = []
            action.payload.data.forEach((el) => {
                arrResult.push({ ...el })
            })

            state.arrReceipt = arrResult
        },
        setCurrentPromotion(state, action) {
            state.currentPromotion = {
                name: action.payload.data.name,
                rate: action.payload.data.rate,
                description: action.payload.data.description,
            }
        },
        setCurrentReceiptDefault(state, action) {
            state.currentReceiptDefault = {
                id: action.payload.data.id,
                fullName: action.payload.data.fullName,
                email: action.payload.data.email,
                phoneNumber: action.payload.data.phoneNumber,
                taxNumber: action.payload.data.taxNumber,
                address: action.payload.data.address,
                cusCode: action.payload.data.cusCode,
            }
        },
    },
})

export const commonActions = commonSlice.actions

export default commonSlice
