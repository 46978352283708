import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "./Card";
import classes from "./SelectDefaultPlaceModal.module.css";
import { SelectBox } from "devextreme-react/select-box";
import { TextBox } from "devextreme-react/text-box";
import { uiActions } from "../../store/ui-slice";
import { placeCartActions } from "../../store/placeCart-slice";

const API_URL = process.env.REACT_APP_URL;

const FieldRender = (props) => {
  return (
    <TextBox className="" defaultValue={props && props.name} readOnly={true} />
  );
};

const ItemRender = (props) => {
  return (
    <div>
      {props.name} {props.description ? `(${props.description})` : ""}
    </div>
  );
};

const Backdrop = (props) => {
  return <div className={classes.backdrop} />;
};

const ModalOverlay = (props) => {
  const dispatch = useDispatch();

  const [dsQuayVe, setDsQuayVe] = useState([]);
  const [dsDiaDiem, setDsDiaDiem] = useState([]);

  const { defaultTicketType } = useSelector((state) => state.placeCart);
  const [selectedOption, setSelectedOption] = useState(defaultTicketType);
  const [selectedCounter, setSelectedCounter] = useState(null);

  const selectHandler = (evt) => {
    // console.log(evt)
    setSelectedOption(evt.selectedItem.ticketTypeId);
  };

  const selectCounterHandler = (evt) => {
    // console.log(evt)
    setSelectedCounter(evt.selectedItem.id);
  };

  const confirmHandler = (evt) => {
    // dispatch(placeCartActions.setDefaultTicketType(selectedOption))
    // dispatch(uiActions.setShowSelectDefaultPlace(false))
    // console.log(`defaultTicketType: ${defaultTicketType}`)
    // console.log(`selectedCounter: ${selectedCounter}`)
    if (!selectedCounter) {
      alert("Hãy chọn quầy vé!");
      return;
    }
    dispatch(
      placeCartActions.setDefaultTicketTypeAndTicketCounter({
        counter: selectedCounter,
        ticketType: selectedOption,
      })
    );
    dispatch(uiActions.setShowSelectDefaultPlace(false));
  };

  useEffect(() => {
    fetch(`${API_URL}/api/quayve/danhsach`)
      .then((res) => res.json())
      .then((data) => {
        setDsQuayVe(data);
      });

    fetch(`${API_URL}/api/DiaDiem/danhsachgiavediadiem`)
      .then((res) => res.json())
      .then((data) => {
        setDsDiaDiem(data.filter((el) => el.typeValue === 1));
      });
  }, []);
  return (
    <Card className={classes.modal}>
      <header className={classes.header}>
        <h5>Hãy chọn địa điểm mặc định và quầy vé</h5>
      </header>
      <div className={classes.content}>
        {/* <p style={{ fontSize: '20px' }}>{props.message}</p> */}
        <SelectBox
          dataSource={dsDiaDiem}
          displayExpr="title"
          valueExpr={"ticketTypeId"}
          searchEnabled={true}
          value={selectedOption}
          onSelectionChanged={selectHandler}
          // className={'select-dx-custom'}
          // itemRender={customItemRender}
        />
        <SelectBox
          dataSource={dsQuayVe}
          placeholder="Vui lòng chọn quầy vé"
          displayExpr="name"
          valueExpr={"id"}
          onSelectionChanged={selectCounterHandler}
          // fieldRender={FieldRender}
          itemRender={ItemRender}
        />
      </div>
      <footer className={classes.actions}>
        {/* <Button onClick={props.onConfirm}>Okay</Button> */}
        <button
          className={"btn btn-primary"}
          type={"button"}
          onClick={confirmHandler}
        >
          Xác nhận
        </button>
      </footer>
    </Card>
  );
};

const SelectDefaultPlaceModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onConfirm={() => console.log("dkm")} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <ModalOverlay
          title={props.title}
          message={props.message}
          onConfirm={() => console.log("dkm")}
        />,
        document.getElementById("overlay-root")
      )}
    </React.Fragment>
  );
};

export default SelectDefaultPlaceModal;
