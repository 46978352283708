import React from "react";

const mainRoutes = [
  {
    path: "/xac-nhan-dat-ve",
    element: React.lazy(() => import("./pages/OrderPageConfirm")),
  },
  {
    path: "/dat-ve",
    element: React.lazy(() => import("./pages/OrderPage")),
  },
  {
    path: "/thanh-toan-dat-ve/:id",
    element: React.lazy(() => import("./pages/ThanhToanDatVe")),
  },
  {
    path: "/lich-su-mua-hang",
    element: React.lazy(() => import("./pages/LichSuMuaHangPage")),
  },
  {
    path: "/thoi-gian-tham-quan",
    element: React.lazy(() => import("./pages/ThoiGianThamQuan")),
  },
  {
    path: "/noi-quy/:id",
    element: React.lazy(() => import("./pages/PrincipleDetailPage")),
  },
  {
    path: "/noi-quy",
    element: React.lazy(() => import("./pages/PrinciplePage")),
  },
  {
    path: "/tour-moi/:tourId",
    element: React.lazy(() => import("./pages/TourMoiDetailPage")),
  },
  {
    path: "/tour-moi",
    element: React.lazy(() => import("./pages/TourMoiPage")),
  },
  {
    path: "/chuong-trinh-khuyen-mai/:id",
    element: React.lazy(() => import("./pages/SaleProgramDetailPage")),
  },
  {
    path: "/chuong-trinh-khuyen-mai",
    element: React.lazy(() => import("./pages/SaleProgram")),
  },
  {
    path: "/gio-hang",
    element: React.lazy(() => import("./pages/GioHangPage")),
  },
  {
    path: "/diem-den/:placeId",
    element: React.lazy(() => import("./pages/DiemDenChiTietPage")),
    // element: React.lazy(() => import('./pages/DestinationDetailPage')),
  },
  {
    path: "/diem-den/*",
    element: React.lazy(() => import("./pages/DiemDenPage")),
  },
  // {
  //   path: "/test-page",
  //   element: React.lazy(() => import("./pages/TestPage")),
  // },
  {
    path: "/danh-sach-ve/:orderId/*",
    element: React.lazy(() => import("./pages/ThongTinChiTietVePage")),
  },
  {
    path: "/thanh-toan",
    element: React.lazy(() => import("./pages/ThanhToanPage")),
  },
  {
    path: "/mua-ve",
    element: React.lazy(() => import("./pages/MuaVePage")),
  },
  {
    path: "/ban-ve",
    element: React.lazy(() => import("./pages/BanVeStep1")),
  },
  {
    path: "/ban-ve-buoc-2",
    element: React.lazy(() => import("./pages/BanVeStep2")),
  },
  {
    path: "/ban-ve-buoc-3",
    element: React.lazy(() => import("./pages/BanVeStep3")),
  },
  {
    path: "/chon-ve",
    element: React.lazy(() => import("./pages/DatVeStep1")),
  },
  {
    path: "/return-ticket",
    element: React.lazy(() => import("./pages/DatVeStep4")),
  },
  {
    path: "/muave-hoantat",
    element: React.lazy(() => import("./pages/DatVeHoanTat")),
  },
  {
    path: "/thong-tin-ve/:orderId/",
    element: React.lazy(() => import("./pages/TrangThongTinVe")),
  },
  {
    path: "/tra-cuu-thong-tin-ve",
    element: React.lazy(() => import("./pages/TicketSearchPage")),
  },
  {
    path: "/ticket-scan",
    // element: React.lazy(() => import('./pages/TicketScanPage')),
    element: React.lazy(() => import("./pages/QuetVePage")),
  },
  {
    path: "/ticket-scan-pc",
    element: React.lazy(() => import("./pages/TicketScanCompPage")),
  },
  {
    path: "/danh-sach-ve/*",
    element: React.lazy(() => import("./pages/TicketManagement")),
  },
  {
    path: "/lich-su-mua-ve",
    element: React.lazy(() => import("./pages/DanhSachVe")),
  },
  {
    path: "/kich-hoat-ve",
    element: React.lazy(() => import("./pages/DanhSachVeLoi")),
  },
  {
    path: "/ticket-invalid",
    element: React.lazy(() => import("./pages/TicketErrorPage")),
  },
  {
    path: "/ticket-detail/:orderId/:cusType",
    element: React.lazy(() => import("./pages/TicketDetailPage")),
  },
  {
    path: "/thong-ke",
    element: React.lazy(() => import("./pages/ThongKePageNew")),
  },
  {
    path: "/thong-ke-ve-huy/:tid",
    element: React.lazy(() => import("./pages/ThongKeVeHuy")),
  },
  {
    path: "/thong-ke-doanh-thu-dia-diem/:tid",
    element: React.lazy(() => import("./pages/ThongKeDoanhThuDiaDiem")),
  },
  {
    path: "/thong-ke-luot-tham-quan/:tid",
    element: React.lazy(() => import("./pages/ThongKeLuotThamQuanNgay")),
  },
  {
    path: "/thong-ke-doanh-thu/:tid",
    element: React.lazy(() => import("./pages/ThongKeDoanhThuNgay")),
  },
  {
    path: "/test-qr",
    element: React.lazy(() => import("./pages/TestQR")),
  },
  {
    path: "/dich-vu",
    element: React.lazy(() => import("./pages/ServicesPage")),
  },
  {
    path: "/dich-vu/:id",
    element: React.lazy(() => import("./pages/ServiceDetailPage")),
  },
  {
    path: "/return-success",
    element: React.lazy(() => import("./pages/ReturnPayPage")),
  },
  {
    path: "/thanh-toan-gio-hang",
    element: React.lazy(() => import("./pages/PaymentPage")),
  },
  {
    path: "/test",
    element: React.lazy(() => import("./pages/TestDropDown")),
  },
  {
    path: "/tong-hop-bien-lai",
    element: React.lazy(() => import("./pages/DanhSachBienLai")),
  },
  {
    path: "/lien-he",
    element: React.lazy(() => import("./pages/LienHePage")),
  },
  {
    path: "/kham-pa",
    element: React.lazy(() => import("./pages/KhamPha")),
  },
  {
    path: "/vedientu",
    element: React.lazy(() => import("./pages/HomePage")),
  },
  {
    path: "/su-kien",
    element: React.lazy(() => import("./pages/EventPage")),
  },
  {
    path: "/su-kien/:eventId",
    element: React.lazy(() => import("./pages/EventDetailPage")),
  },
  {
    path: "/dieu-khoan/*",
    element: React.lazy(() => import("./pages/DieuKhoanPageNew")),
  },
  {
    path: "/thong-tin-nguoi-dung",
    element: React.lazy(() => import("./pages/ThongTinNguoiDung")),
  },
  {
    path: "/mua-nhieu-ve",
    element: React.lazy(() => import("./pages/BuyTicketMultiPlacePage")),
  },
];

export default mainRoutes;
