import { createSlice } from '@reduxjs/toolkit'

const quantrithongkeSlice = createSlice({
    name: 'quantrithongke',
    initialState: {
        isLoaded: true,
        data: null,
        error: null,
    },
    reducers: {
        quanTriThongKeRequest(state, action) {
            state.isLoaded = false
        },
        phanQuyenTemplateSuccess(state, action) {
            state.isLoaded = true
            state.error = null
        },
        phanQuyenTemplateFailed(state, action) {
            state.isLoaded = true
            state.error = action.payload
        },
    },
})

export const quantrithongkeActions = quantrithongkeSlice.actions

export default quantrithongkeSlice
