import { createSlice } from '@reduxjs/toolkit'

const placeCartSlice = createSlice({
    name: 'placeCart',
    initialState: {
        items: [],
        totalPrice: 0,
        quickOrderObject: {
            placeId: '',
            placeName: '',
            imageID: '',
            imageUrl: '',
            details: [],
            totalPrice: 0,
        },
        defaultTicketType: 1,
        selectedTicketCounter: 0,
    },
    reducers: {
        reset(state) {
            // state.items = []
            // state.totalPrice = 0
            state.quickOrderObject = {
                placeId: '',
                placeName: '',
                imageID: '',
                imageUrl: '',
                details: [],
                totalPrice: 0,
            }
            state.defaultTicketType = 0
        },
        setDefaultTicketType(state, action) {
            state.defaultTicketType = action.payload
        },
        setSelectedTicketCounter(state, action) {
            state.selectedTicketCounter = action.payload
        },
        setDefaultTicketTypeAndTicketCounter(state, action) {
            state.selectedTicketCounter = action.payload.counter
            state.defaultTicketType = action.payload.ticketType
        },
        checkAll(state) {
            const currentItems = JSON.parse(JSON.stringify(state.items))

            currentItems.forEach((el) => (el.isSelected = true))
            let totalPrice = 0
            currentItems.forEach((el) => {
                if (el.isSelected) {
                    totalPrice += el.totalPrice
                }
            })
            state.items = currentItems
            state.totalPrice = totalPrice
        },
        resetCart(state) {
            state.items = []
            state.totalPrice = 0
        },
        resetSelect(state) {
            const currentItems = JSON.parse(JSON.stringify(state.items))

            currentItems.forEach((el) => (el.isSelected = false))
            state.items = currentItems
            state.totalPrice = 0
        },
        getUserCart(state, action) {
            const currentItems = JSON.parse(JSON.stringify(action.payload.data))
            let totalPrice = 0
            currentItems.forEach((el) => {
                if (el.isSelected) {
                    totalPrice += el.totalPrice
                }
            })
            state.items = currentItems
            state.totalPrice = totalPrice
        },
        selectItemToPay(state, action) {
            const selectedTicket = action.payload.ticketTypeId
            const isChecked = action.payload.isChecked
            // const currentItems = structuredClone(state.items)
            const currentItems = JSON.parse(
                JSON.stringify(
                    // const currentItems = structuredClone(state.items)
                    state.items
                )
            )
            currentItems.forEach((el) => {
                if (el.ticketTypeId === selectedTicket && isChecked) {
                    el.isSelected = true
                }

                if (el.ticketTypeId === selectedTicket && !isChecked) {
                    el.isSelected = false
                }
            })

            let totalPrice = 0
            currentItems.forEach((el) => {
                if (el.isSelected) {
                    totalPrice += el.totalPrice
                }
            })
            state.items = currentItems
            state.totalPrice = totalPrice
        },
        removeCartItem(state, action) {
            const currentItems = JSON.parse(JSON.stringify(state.items))
            const remainItems = currentItems.filter(
                (el) => el.cartId !== +action.payload
            )
            let totalPrice = 0
            remainItems.forEach((el) => {
                if (el.isSelected) {
                    totalPrice += el.totalPrice
                }
            })
            state.items = remainItems
            state.totalPrice = totalPrice
        },
        addItemToCart(state, action) {
            const selectedPlace = { ...action.payload.selectedPlace }
            let selectedQuickPlace
            const currentItems = [...state.items]

            let existingPlace = currentItems.find(
                (el) => el.ticketTypeId === selectedPlace.ticketTypeId
            )
            if (existingPlace) {
                existingPlace.totalPrice = 0
                existingPlace.details.forEach((el) => {
                    if (el.custommerTypeId === 1) {
                        el.quantity = el.quantity + 1
                    }
                    existingPlace.totalPrice =
                        existingPlace.totalPrice + el.price * el.quantity
                })
            } else {
                selectedQuickPlace = {
                    ticketTypeId: selectedPlace.ticketTypeId,
                    placeId: selectedPlace.id,
                    placeName: selectedPlace.title,
                    imageID: selectedPlace.imageID,
                    imageUrl: selectedPlace.imageUrl,
                    details: [],
                    totalPrice: 0,
                    isSelected: true,
                    cartId: selectedPlace.cartId || 0,
                    listPlaceName: selectedPlace.listPlaceName,
                    typeValue: selectedPlace.typeValue,
                }

                const vTemp = selectedPlace.priceDetail || []

                vTemp.forEach((el) => {
                    const item = { ...el }
                    item.quantity = 0
                    selectedQuickPlace.details.push(item)
                })
                selectedQuickPlace.details.forEach((el) => {
                    if (el.custommerTypeId === 1) {
                        el.quantity = el.quantity + 1
                    }
                    selectedQuickPlace.totalPrice =
                        selectedQuickPlace.totalPrice + el.price * el.quantity
                })
                currentItems.push(selectedQuickPlace)
            }
            let totalPrice = 0
            currentItems.forEach((el) => {
                if (el.isSelected) {
                    totalPrice += el.totalPrice
                }
            })
            state.items = currentItems
            state.totalPrice = totalPrice
        },

        turningQuantity(state, action) {
            const { custommerTypeId, count, ticketTypeId } = action.payload
            const currentItems = [...state.items]
            let existingPlace = currentItems.find(
                (el) => el.ticketTypeId === ticketTypeId
            )
            let currentType = existingPlace.details.find(
                (el) => el.custommerTypeId === custommerTypeId
            )
            if (count < 0) {
                if (currentType.quantity > 0) {
                    currentType.quantity = currentType.quantity + count
                    existingPlace.totalPrice =
                        existingPlace.totalPrice + currentType.price * count
                }
            } else {
                currentType.quantity = currentType.quantity + count
                existingPlace.totalPrice =
                    existingPlace.totalPrice + currentType.price * count
            }
            let totalPrice = 0
            currentItems.forEach((el) => {
                if (el.isSelected) {
                    totalPrice += el.totalPrice
                }
            })
            state.items = currentItems
            state.totalPrice = totalPrice
        },
        increaseAdultQuantity(state, action) {
            const placeId = action.payload.placeId
            const existingItem = state.items.find((item) => item.id === placeId)
            if (!existingItem) return
            existingItem.adultQuantity += 1
            // state.items = [existingItem];

            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },
        decreaseAdultQuantity(state, action) {
            const placeId = action.payload.placeId
            const existingItem = state.items.find((item) => item.id === placeId)
            if (!existingItem) return
            if (existingItem.adultQuantity === 0) return
            existingItem.adultQuantity -= 1
            // state.items = [existingItem];
            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },
        increaseChildrenQuantity(state, action) {
            const placeId = action.payload.placeId
            const existingItem = state.items.find((item) => item.id === placeId)
            if (!existingItem) return
            existingItem.childrenQuantity += 1
            // state.items = [existingItem];
            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },
        decreaseChildrenQuantity(state, action) {
            const placeId = action.payload.placeId
            const existingItem = state.items.find((item) => item.id === placeId)
            if (!existingItem) return
            if (existingItem.childrenQuantity === 0) return
            existingItem.childrenQuantity -= 1
            // state.items = [existingItem];
            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },

        updateChildrenQuantity(state, action) {
            const placeId = action.payload.placeId
            const existingItem = state.items.find((item) => item.id === placeId)
            if (!existingItem) return
            existingItem.childrenQuantity = action.payload.count
            // state.items = [existingItem];
            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },

        updateAdultQuantity(state, action) {
            const placeId = action.payload.placeId
            const existingItem = state.items.find((item) => item.id === placeId)
            if (!existingItem) return
            existingItem.adultQuantity = action.payload.count
            // state.items = [existingItem];
            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },
        quickOrderTuningQuantity(state, action) {
            const { count, custommerTypeId } = action.payload
            const currentSelectedQuickPlace = JSON.parse(
                JSON.stringify({ ...state.quickOrderObject })
            )
            currentSelectedQuickPlace.totalPrice = 0
            currentSelectedQuickPlace.details.forEach((el) => {
                if (el.custommerTypeId === custommerTypeId) {
                    el.quantity = el.quantity + count
                    if (el.quantity < 0) {
                        el.quantity = 0
                    }
                }

                currentSelectedQuickPlace.totalPrice =
                    currentSelectedQuickPlace.totalPrice +
                    el.price * el.quantity
            })

            state.quickOrderObject = { ...currentSelectedQuickPlace }
        },
        quickOrderChangeQuantity(state, action) {
            const { count, custommerTypeId } = action.payload
            const currentSelectedQuickPlace = JSON.parse(
                JSON.stringify({ ...state.quickOrderObject })
            )
            currentSelectedQuickPlace.totalPrice = 0
            currentSelectedQuickPlace.details.forEach((el) => {
                if (el.custommerTypeId === custommerTypeId) {
                    el.quantity = count
                }

                currentSelectedQuickPlace.totalPrice =
                    currentSelectedQuickPlace.totalPrice +
                    el.price * el.quantity
            })

            state.quickOrderObject = { ...currentSelectedQuickPlace }
        },
        initQuickOrder(state, action) {
            const selectedPlace = { ...action.payload.selectedPlace }
            const selectedQuickPlace = {
                placeId: selectedPlace.id,
                placeName: selectedPlace.title,
                imageID: selectedPlace.imageID,
                imageUrl: selectedPlace.imageUrl,
                details: [],
                totalPrice: 0,
            }

            const vTemp = selectedPlace.priceDetail || []

            vTemp.forEach((el) => {
                const item = { ...el }

                item.quantity = 0
                selectedQuickPlace.details.push(item)
            })

            state.quickOrderObject = selectedQuickPlace
        },
        replaceCartItem(state, action) {
            const selectedPlace = { ...action.payload.selectedPlace }
            // const strJsonPlace = JSON.stringify(selectedPlace);
            // const selectedQuickPlace = JSON.parse(strJsonPlace);
            //   selectedPlace.adultQuantity = action.payload.adultQuantity;
            //   selectedPlace.childrenQuantity = action.payload.childrenQuantity;

            const selectedQuickPlace = {
                placeId: selectedPlace.id,
                placeName: selectedPlace.title,
                imageID: selectedPlace.imageID,
                imageUrl: selectedPlace.imageUrl,
                details: [],
                totalPrice: 0,
            }

            const vTemp = selectedPlace.priceDetail || []

            const currentSelectedQuickPlace = JSON.parse(
                JSON.stringify({ ...state.quickOrderObject })
            )

            vTemp.forEach((el) => {
                const item = { ...el }

                item.quantity = 0

                currentSelectedQuickPlace.details.forEach((subel) => {
                    if (subel.custommerTypeId === item.custommerTypeId) {
                        item.quantity = subel.quantity
                    }
                })

                selectedQuickPlace.totalPrice =
                    selectedQuickPlace.totalPrice + item.price * item.quantity
                selectedQuickPlace.details.push(item)
            })

            // selectedQuickPlace.details.forEach((el) => {
            //     selectedQuickPlace.totalPrice =
            //         selectedQuickPlace.totalPrice + el.price * el.quantity
            // })

            // vTemp.forEach((el) => {
            //     const item = { ...el }
            //     item.quantity = 0
            //     if (item.custommerTypeId === 1) {
            //         item.quantity = action.payload.adultCount
            //     }
            //     if (item.custommerTypeId === 2) {
            //         item.quantity = action.payload.childrenCount
            //     }

            //     selectedQuickPlace.totalPrice =
            //         selectedQuickPlace.totalPrice + item.price * item.quantity
            //     selectedQuickPlace.details.push(item)
            // })

            // selectedPlace.adultQuantity = action.payload.adultCount;
            // selectedPlace.childrenQuantity = action.payload.childrenCount;
            // state.items = [selectedPlace];
            // let mPrice = 0;
            // state.items.forEach((el) => {
            //   mPrice =
            //     mPrice +
            //     el.adultQuantity * el.adultPrice +
            //     el.childrenQuantity * el.childrenPrice;
            // });
            // state.totalPrice = mPrice;
            state.quickOrderObject = selectedQuickPlace
        },
        removeItemFromCart(state, action) {
            console.log('remove')
            const currentItems = [...state.items]
            const resultItems = []
            currentItems.forEach((el) => {
                if (action.payload.indexOf(el.id) === -1) {
                    resultItems.push(el)
                }
            })

            state.items = [...resultItems]
            let mPrice = 0
            state.items.forEach((el) => {
                mPrice =
                    mPrice +
                    el.adultQuantity * el.adultPrice +
                    el.childrenQuantity * el.childrenPrice
            })
            state.totalPrice = mPrice
        },
        removeOneItemFromCart(state, action) {
            // const currentItems = [...state.items]
            const currentItems = JSON.parse(JSON.stringify([...state.items]))
            const resultItems = []
            currentItems.forEach((el) => {
                // console.log(el)
                if (+el.ticketTypeId !== +action.payload.placeId) {
                    resultItems.push({ ...el })
                }
            })
            // state.items = [...resultItems]
            state.items = JSON.parse(JSON.stringify([...resultItems]))
            let mPrice = 0
            state.items.forEach((el) => {
                el.details.forEach((subEl) => {
                    mPrice += subEl.price * subEl.quantity
                })
            })
            state.totalPrice = mPrice
        },
    },
})

export const placeCartActions = placeCartSlice.actions

export default placeCartSlice
