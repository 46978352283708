import LoadingPanel from "./components/common/LoadingPanel";
import Layout from "./components/layout/Layout";

import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import {
  getArrCustomerType,
  getArrReceipt,
  getCurrentPromotion,
  getReceiptDefault,
} from "./store/common-actions";

import { AuthProvider } from "./contexts/JWTAuthContext";
import mainRoutes from "./main-routes";

import { useLocation } from "react-router-dom";
import LoginPanel from "./components/common/LoginPanel";
import RegisterPanel from "./components/common/RegisterPanel";
import MainHeader from "./components/MainHeader";
import SelectDefaultPlaceModal from "./components/UI/SelectDefaultPlaceModal";

const MainContentLazy = (props) => {
  const isLoading = useSelector((state) => state.ui.showLoading);
  const { showLoginPanel, showRegisterPanel, showSelectDefaultPlace } =
    useSelector((state) => state.ui);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    // console.log(location.pathname)
    if (!location.pathname.includes("ticket-detail")) {
      dispatch(getArrCustomerType());
      dispatch(getArrReceipt());
      dispatch(getCurrentPromotion());
      dispatch(getReceiptDefault());
    }
  }, []);
  return (
    <AuthProvider>
      <Layout>
        {isLoading && <LoadingPanel />}
        {showLoginPanel && <LoginPanel />}
        {showRegisterPanel && <RegisterPanel />}
        {showSelectDefaultPlace && <SelectDefaultPlaceModal />}
        <MainHeader />
        <Routes>
          <Route path="/" element={<Navigate replace to={"/vedientu"} />} />
          {mainRoutes.map((item, i) => {
            return (
              <Route
                key={`main_${i}`}
                path={item.path}
                element={
                  <Suspense fallback={<LoadingPanel />}>
                    <item.element />
                  </Suspense>
                }
              />
            );
          })}
        </Routes>
      </Layout>
    </AuthProvider>
  );
};

export default MainContentLazy;
