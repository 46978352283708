import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        showLoading: false,
        isQuickOrder: false,
        showLoginPanel: false,
        showRegisterPanel: false,
        isOrder: false,
        showSelectDefaultPlace: false,
    },
    reducers: {
        setShowLoading(state, action) {
            state.showLoading = action.payload
        },
        setOrderType(state, action) {
            state.isQuickOrder = action.payload
        },
        setShowLogin(state, action) {
            state.showLoginPanel = action.payload
        },
        setShowRegister(state, action) {
            state.showRegisterPanel = action.payload
        },
        setIsOrder(state, action) {
            state.isOrder = action.payload
        },
        setShowSelectDefaultPlace(state, action) {
            state.showSelectDefaultPlace = action.payload
        },
    },
})

export const uiActions = uiSlice.actions

export default uiSlice
